import React from 'react';
import { RocketTwoTone, MessageTwoTone } from '@ant-design/icons';
import { Typography, Button, Space, Divider } from 'antd';

const { Title } = Typography;

const HomePage = ({ history }) => {
  return (
    <div id="home-header" align="center">
      <Title style={{marginBottom: '-10px'}}>
        {`<`} Liz Watkins {`/>`}
      </Title>
      <Title level={3}>
        <i>miami-based full stack developer</i>
      </Title>
      <Divider />
      <Space wrap style={{justifyContent: 'center'}}>
        <Button
          style={{display: 'flex', alignItems: 'center'}}
          type="primary"
          size="large"
          icon={<RocketTwoTone style={{ fontSize: '1.5rem' }} twoToneColor="green"/>}
          onClick={() => history.push('/projects')}
        >
          Portfolio
        </Button>
        <Button
          style={{display: 'flex', alignItems: 'center'}}
          className="ghost-btn"
          type="ghost"
          size="large"
          icon={<MessageTwoTone style={{ fontSize: '1.5rem' }} twoToneColor="#4BBE58"/>}
          onClick={() => history.push('/contact')}
        >
          Contact
        </Button>
      </Space>
    </div>
  )
}

export default HomePage;
