import React, { useState, useEffect } from 'react';
import ProjectCardAction from '../components/ProjectCardAction';
import { ExperimentTwoTone, RocketTwoTone, ThunderboltTwoTone } from '@ant-design/icons';
import { Card, Divider, Image, Space, Typography, Spin, Button, BackTop } from 'antd';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const { Title, Paragraph, Link } = Typography;

const builder = imageUrlBuilder(sanityClient);
const urlFor = source => builder.image(source);

const ProjectList = () => {
  const [projects, setProjects] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState(null);
  const [iconSpin, setIconSpin] = useState({ full: true, misc: false, all: false });
  const [infoText, setInfoText] = useState('full stack: front end, back end, the works!');

  const fetchProjects = () => {
    sanityClient
      .fetch(
        `*[_type == "project" && include == true] | order(date desc) {
        title,
        slug,
        links,
        description,
        categories,
        date,
        mainImage{
          asset->{
          _id,
          url
        }
        }
      }`
    ).then(data => {
      const filtered = data.filter(p => p.categories[0]._ref === "bff7b08f-c4f6-48ae-8901-616137c11370");

      setProjects(data);
      setFilteredProjects(filtered);
    }).catch(console.error);
  }

  const toggleView = view => {
    let filteredProjects = [];
    switch (view) {
      case 'full':
        filteredProjects = projects.filter(p => p.categories[0]._ref === "9a7f098a-5d83-4733-8696-39ad48122d08");
        setIconSpin({ full: false, misc: true, all: false });
        setInfoText('mini projects, experiments, coding challenges!');
        break;
      case 'misc':
        filteredProjects = projects.filter(p => p.categories[0]._ref === "bff7b08f-c4f6-48ae-8901-616137c11370");
        setIconSpin({ full: true, misc: false, all: false });
        setInfoText('full stack: front end, back end, the works!');
        break;
      case 'all':
        filteredProjects = projects;
        setIconSpin({ full: false, misc: false, all: true });
        setInfoText('everything!');
        break;
     }
    setFilteredProjects(filteredProjects);
  }

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <Typography align="center" style={{width: '90%'}}>
        <Title>
          {`<`} Projects {`/>`}
        </Title>
        <Paragraph>
          Click the buttons below to filter by project type!<br/>
        </Paragraph>
        <Paragraph>
          Or if you want to see more of my work, check out my
          <Link strong href="https://github.com/e-a-w" target="_blank"> GitHub </Link>
          & <Link strong href="https://repl.it/@ewatkins" target="_blank">repl.it</Link>!
        </Paragraph>
        <Divider />
        <Space size="large" wrap style={{justifyContent: 'center'}}>
          <Button
            key="full"
            size="large"
            onClick={() => toggleView('misc')}
            type="primary"
            style={{ display: 'flex', alignItems: 'center'}}
            icon={<RocketTwoTone
              style={{ fontSize: '1.2rem', paddingTop: '3px' }}
              twoToneColor="green"
              spin={iconSpin.full}
            />}
          >
            Full Stack
          </Button>
          <Button
            key="misc"
            size="large"
            onClick={() => toggleView('full')}
            type="primary"
            style={{ display: 'flex', alignItems: 'center'}}
            icon={<ExperimentTwoTone
              style={{ fontSize: '1.2rem', paddingTop: '3px' }}
              twoToneColor="green"
              spin={iconSpin.misc}
            />}
          >
            Misc
          </Button>
          <Button
            key="all"
            size="large"
            onClick={() => toggleView('all')}
            type="primary"
            style={{ display: 'flex', alignItems: 'center'}}
            icon={<ThunderboltTwoTone
              style={{ fontSize: '1.2rem', paddingTop: '5px' }}
              twoToneColor="green"
              spin={iconSpin.all}
            />}
          >
            All
          </Button>
        </Space>
        <Title level={5}>{infoText}</Title>
        <Divider />
      </Typography>

      <Space
        align="block"
        wrap
        size="large"
        style={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}
      >
        {filteredProjects ? filteredProjects.map(project => (
          <Card
            headStyle={{ textAlign: 'center' }}
            style={{ maxWidth: '350px', borderTop: '2px solid #4BBE58', marginLeft: '10%' }}
            key={project.slug.current}
            title={
              <Space style={{wordWrap: 'break-word'}}>
                <Title level={3}>
                  {(project.categories[0]._ref === "9a7f098a-5d83-4733-8696-39ad48122d08")
                    ? <ExperimentTwoTone twoToneColor="#4BBE58" />
                    : <RocketTwoTone twoToneColor="#4BBE58" />}
                </Title>
                <Title level={4}>
                  {project.title}
                </Title>
              </Space>
            }
            cover={
              project?.mainImage ?
                <Image src={urlFor(project.mainImage).url()} alt="Project Preview" />
              : <Spin />
            }
            actions={[
              <ProjectCardAction site='learn' url={`/projects/${project.slug.current}`} internal/>,
              (project.links[0] && <ProjectCardAction site={project.links[0].link} url={project.links[0].url} />),
              (project.links[1] && <ProjectCardAction site={project.links[1].link} url={project.links[1].url} />)
            ]}
          >
            <BlockContent
              blocks={project.description}
              projectId={sanityClient.client?.config.projectId}
              dataset={sanityClient.client?.config.dataset}
            />
          </Card>
        )) : <Spin size="large"/>}
      </Space>
      <BackTop visibilityHeight="200">
        <Button type="primary">
          Scroll to Top
        </Button>
      </BackTop>
    </>
  )
}

export default ProjectList;
