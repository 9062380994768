import About from './pages/About';
import Contact from './pages/Contact';
import HomePage from './pages/HomePage';
import Navigation from './components/Navigation';
import ProjectList from './pages/ProjectList';
import ProjectPage from './pages/ProjectPage';
import React from 'react';
import Resume from './pages/Resume';
import Site from './pages/Site';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout, Result } from 'antd';

const { Sider, Content } = Layout;

const App = () => {
  return (
    <Router>
      <Layout>
        <Sider
          collapsible
          breakpoint="md"
          width="135"
        >
          <Navigation />
        </Sider>
        <Content id="page-content">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/site" component={Site} />
            <Route exact path="/resume" component={Resume} />
            <Route exact path="/projects" component={ProjectList} />
            <Route exact path="/projects/:slug" component={ProjectPage} />
            <Route path="/">
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
              />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
