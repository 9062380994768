import React from 'react';
import { Typography, Form, Input, Button, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const { Title, Paragraph } = Typography;
const formName = 'contactForm';

const Contact = () => {
  const [form] = Form.useForm();

  const showSuccess = () => {
    Modal.success({
      title: 'message sent successfully!',
      content: 'Thanks for contacting me! I\'ll respond as soon as possible.',
    });
  }

  const showError = () => {
    Modal.error({
      title: 'message did not send',
      content: 'Oops, something went wrong! Please try again.',
    });
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
      .join(`&`);
  }

  const handleSubmit = values => {
    // from https://joeczubiak.com/netlify-contact-form-with-react-ant-design/
    if (values[`bot-field`] === undefined) {
      delete values[`bot-field`]
    }

    fetch(`/`, {
      method: `POST`,
      headers: { 'Content-Type': `application/x-www-form-urlencoded` },
      body: encode({
          'form-name': formName,
          ...values,
      }),
    })
    .then(() => showSuccess())
      .catch(error => showError(error))
    
    form.resetFields();
  }

  return (
    <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Title align="center">
        {`<`} Contact {`/>`}
      </Title>
      <Paragraph>
        Fill out the form below to send me an email!
      </Paragraph>
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%', maxWidth: '500px' }}
        onFinish={handleSubmit}
      >
        {/* hidden input for Netlify */}
        <Form.Item
          label="Don't fill this out"
          className={`hidden`}
          style={{ display: `none` }}
          name="bot-field"
        >
          <Input type={`hidden`} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" placeholder="type your name here!"/>
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" type="email" placeholder="type your email here!"/>
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true }]}
        >
          <TextArea autoComplete="off" rows="8" placeholder="type your message here!"/>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  )
}

export default Contact;
