import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import {
  MailOutlined,
  GithubFilled,
  LinkedinOutlined,
  IdcardOutlined,
  HomeOutlined,
  LaptopOutlined,
  InfoCircleOutlined,
  FilePdfOutlined
} from '@ant-design/icons';

const Navigation = () => {
  const history = useHistory();

  const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleClick = ({ key }) => {
    switch (key) {
      case 'home':
        history.push('/');
        break;
      case 'github':
        openInNewTab('https://github.com/e-a-w/');
        break;
      case 'linkedin':
        openInNewTab('https://www.linkedin.com/in/eawatkins/');
        break;
      default:
        history.push(`/${key}`);
        break;
    }
  };

  return (
    <Menu
      mode="inline"
      theme="dark"
      id="side-menu"
      onClick={handleClick}
    >
      <Menu.ItemGroup className="side-menu-group">
        <Menu.Item key="home" icon={<HomeOutlined />}>
          Home
        </Menu.Item>
        <Menu.Item key="about" icon={<IdcardOutlined />}>
          About
        </Menu.Item>
        <Menu.Item key="projects" icon={<LaptopOutlined />}>
          Projects
        </Menu.Item>
         <Menu.Item key="site" icon={<InfoCircleOutlined />}>
          Site
        </Menu.Item>
        <Menu.Item key="contact" icon={<MailOutlined />}>
          Contact
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup className="side-menu-group">
        <Menu.Item key="github" icon={<GithubFilled />}>
          GitHub
        </Menu.Item>
        <Menu.Item key="linkedin" icon={<LinkedinOutlined />}>
          LinkedIn
        </Menu.Item>
        <Menu.Item key="resume" icon={<FilePdfOutlined />}>
          Resume
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )
}

export default Navigation
