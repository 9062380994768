import React, { useState, useEffect } from 'react';
import { Image, Button, Spin } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../client';

const builder = imageUrlBuilder(sanityClient);
const urlFor = source => builder.image(source);

const Resume = () => {
  const [pdf, setPDF] = useState(null);
  const [img, setImg] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "author"] {
          "resumeURL": resume.asset->url,
          resumeImg{
            asset->{
              _id,
              url
              }
          },
        }`,
      ).then(data => {
        setPDF(data[0].resumeURL);
        setImg(urlFor(data[0].resumeImg).url())
      });
    }, []);

  return (
    <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Button
        icon={<FilePdfOutlined/>}
        style={{ marginBottom: "20px" }}
        type="primary" href={`${pdf}?dl=liz-watkins-resume.pdf`}
      >
        Download as PDF?
      </Button>
      {img ? <Image style={{ maxWidth: "800px" }} src={img}></Image> : <Spin size="large" />}
    </div>
  )
}

export default Resume
