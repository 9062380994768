import React from 'react';
import { Button } from 'antd';
import { GithubOutlined, EyeOutlined } from '@ant-design/icons';

const ProjectCardLinks = ({ site, url }) => {
  const iconStyle = {
    transform: 'translateY(12%)'
  }

  return (
      <Button
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 'px', paddingTop: '5px'
        }}
        type="primary"
        icon={site === 'live' ? <EyeOutlined style={iconStyle} /> : <GithubOutlined style={iconStyle} />}
        size="large"
        href={url}
        target="_blank"
      >
        {site === 'live' ? 'View It Live!' : 'GitHub Repository'}
    </Button>
  )
}

export default ProjectCardLinks;
