import React, { useState, useEffect } from 'react';
import ProjectCardLinks from '../components/ProjectCardLinks';
import { Spin, Card, Typography, Divider, Button, Image, Skeleton, Space, Result } from 'antd';
import sanityClient from '../client';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(sanityClient);
const urlFor = source => builder.image(source);

const { Title } = Typography;

const ProjectPage = ({ match, history }) => {
  const { slug } = match.params;
  const [project, setProject] = useState(null);
  const [img, setImg] = useState(null);
  const [exists, setExists] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         description,
         tech,
         links
       }`, { slug }
      )
      .then(data => {
        if (data[0]) {
          setProject(data[0]);
          setImg(urlFor(data[0]?.mainImage).url());
        } else {
          setExists(false);
        }
      })
      .catch(console.error);
  }, [slug])

  return (
    <>
      { exists ? <Card
        style={{ maxWidth: '600px', width: '90%', borderTop: '2px solid #4BBE58' }}
        headStyle={{ textAlign: 'center', paddingTop: '50px', position: 'relative' }}
        title={
          <>
            <Button
              type="link"
              onClick={() => history.goBack()}
              style={{ position: 'absolute', top: '10px', left: '10px' }}
            >
              {`<< `}Go back
            </Button>
            <Title level={2}>{project?.title}</Title>
            <Space wrap style={{ justifyContent: 'center', marginTop: '15px' }}>
              {project?.links.map(link => <ProjectCardLinks site={link.link} url={link.url} key={link.link} />)}
            </Space>
          </>
        }
      >
        {
          project ?
            <Typography>
              <Title level={5}>Description:</Title>
              <BlockContent
                blocks={project.description}
                projectId={sanityClient.client?.config.projectId}
                dataset={sanityClient.client?.config.dataset}
              />
              <Title level={5}>Tech Used:</Title>
              <BlockContent
                blocks={project.tech}
                projectId={sanityClient.client?.config.projectId}
                dataset={sanityClient.client?.config.dataset}
              />
            </Typography>
            : <Skeleton active />
        }
        <Divider />
        {img ? <Image
          src={img}
          alt="Project Image"
        /> : <Spin />
        }
      </Card>
        :
      <Result
        status="error"
        title="Hmmm..."
        subTitle="Sorry, having trouble finding that page. Double-check the url or refresh to try again?"
      />
      }
    </>
  )
}

export default ProjectPage;
