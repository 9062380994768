import React, { useEffect, useState } from 'react';
import { Skeleton, Typography } from 'antd';
import sanityClient from '../client';
import BlockContent from '@sanity/block-content-to-react';

const { Title, Paragraph } = Typography;

const Site = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post" && slug.current == "about-this-site"] { body }`
      )
      .then(data => {
        setData(data[0]);
      })
      .catch(console.error);
    }, [])
  
  return (
    <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Title align="center">
        {`<`} About This Site {`/>`}
      </Title>
      <Paragraph>
        {data ? <BlockContent
          blocks={data?.body}
          projectId={sanityClient.client?.config.projectId}
          dataset={sanityClient.client?.config.dataset}
        /> : <Skeleton />}
      </Paragraph>
    </div>
  )
}

export default Site
