import React, { useState, useEffect } from 'react';
import { GithubOutlined, LinkedinFilled, CodepenCircleFilled, CodeFilled } from '@ant-design/icons';
import { Typography, Button, Space, Divider, Skeleton } from 'antd';
import sanityClient from '../client';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';

const { Title, Paragraph } = Typography;

const builder = imageUrlBuilder(sanityClient);
const urlFor = source => builder.image(source);

const About = () => {
  const [me, setMe] = useState(null);
  const [img, setImg] = useState(null);
  const [links, setLinks] = useState(null);

useEffect(() => {
  sanityClient
    .fetch(
      `*[_type == "author"] {
        image{
          asset->{
            _id,
            url
            }
          },
        bio,
        links
      }`,
    ).then(data => {
      setMe(data[0].bio);
      setImg(urlFor(data[0].image).url());
      setLinks(data[0].links);
    }).catch(console.error);
  }, []);

  return (
    <>
      <Title align="center">
        {`<`} About {`/>`}
      </Title>
      <Typography align="left" style={{maxWidth: '550px', width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '15px'}}>
        {me ? <Paragraph>
          <BlockContent
            blocks={me}
            projectId={sanityClient.client?.config.projectId}
            dataset={sanityClient.client?.config.dataset}
          />
        </Paragraph> : <Skeleton />}
        <Divider />
      </Typography>
      <Paragraph align="center">
        You can also find me at:
      </Paragraph>
      <Space wrap style={{ justifyContent: 'center' }}>
        <Button style={{ color: 'green' }} href={links?.linkedin} target="_blank" icon={<LinkedinFilled />}>
          LinkedIn
        </Button>
        <Button style={{ color: 'green' }} href={links?.github} target="_blank" icon={<GithubOutlined />}>
          GitHub
        </Button>
      </Space>
    </>
  )
}

export default About;
