import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GithubOutlined, EyeOutlined, ExperimentOutlined } from '@ant-design/icons';

const ProjectCardAction = ({ site, url, internal }) => {
  const [data, setData] = useState({text: null, icon: null});

  const setInfo = site => {
    switch (site) {
      case 'github':
        setData({ text: 'GitHub', icon: <GithubOutlined style={{ fontSize: '1.2em' }}/>})
        break;
      case 'live':
        setData({text: 'View It Live!', icon: <EyeOutlined style={{ fontSize: '1.2em' }}/>})
        break;
      case 'learn':
        setData({text: 'Learn More!', icon: <ExperimentOutlined style={{ fontSize: '1.2em' }}/>})
        break;
      default:
        setData({text: null, icon: null})
        break;
    }
  }

  useEffect(() => {
    setInfo(site);
  }, [])

  return (
    <>
      {internal ?
        (<Link to={url}>
          <div style={{ marginBottom: "5px" }}>{data.text}</div>
          {data.icon}
        </Link>)
        :
        (<a href={url} target="_blank" rel="noopenner noreferrer">
          <div style={{ marginBottom: "5px" }}>{data.text}</div>
          {data.icon}
        </a>)
      }
    </>
 )
}

export default ProjectCardAction;
